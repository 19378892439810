import logo from './assets/img/logo/plus-white.svg'
import mockup from './assets/img/welcome/Mockupv2.png'
import shapeBottom from './assets/img/hero_shape.svg'
import messe from './assets/img/AP2023_Wir-sind-dabei_Socials_1200x630px.png'



function RedBannerLarge() {
  return (
    <div className="d-none d-md-block">
      <div
        style={{
          position: "absolute",
          backgroundColor: "red",
          color: "white",
          fontWeight: "bold",
          fontSize: "1.5rem",
          padding: "10px",
          borderRadius: "50%",
          width: "320px",
          height: "320px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          transform: "rotate(-15deg) translate(0px, -27%)",
          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.4)"
        }}
      >
        <h3
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "1.9rem",
            padding: "10px",
            textAlign: "center",
          }}>
          Jetzt auch mit:
          <p style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "1.5rem",
            padding: "10px",
            textAlign: "center",
          }}>XRechnung und digitaler Unterschrift</p>
        </h3>
      </div>
    </div>
  );
}

function RedBannerSmall() {
  return (
    <div className="d-md-none">
      <div
        style={{
          position: "absolute",
          backgroundColor: "red",
          color: "white",
          fontWeight: "bold",
          fontSize: "1.5rem",
          padding: "10px",
          borderRadius: "50%",
          width: "140px",
          height: "140px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          transform: "rotate(-15deg) translate(0px, -10%)",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)"
        }}
      >
        <h3
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "0.8rem",
            padding: "10px",
            textAlign: "center",
          }}>
          Jetzt auch mit:
          <p style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "0.7rem",
            lineHeight: "1.2",
            padding: "10px",
            textAlign: "center",
          }}>XRechnung und digitaler Unterschrift</p>
        </h3>
      </div>
    </div>
  );
}




function App() {
  return (
    <section id="home" className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
      <div className="container">
        <div className="row align-items-center">
          {/* <!-- Welcome Intro Start --> */}
          <div className="col-12 col-md-7 col-lg-4">
            <div className="welcome-intro">
              <h1 className="text-white">Mehr Zeit für Menschlichkeit</h1>
            </div>
          </div>
          <div className="col-12 col-md-1 col-lg-3">
            {/* <!-- Welcome Thumb --> */}
            <div className="welcome-thumb text-center mx-auto">
              <RedBannerSmall />
              <img src={logo} alt="+" loading="lazy" />
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-5">
            <div className="welcome-thumb text-right mx-auto">
              <img src={mockup} alt="Bildschirm" loading="lazy" />
            </div>
          </div>
        </div>
        <RedBannerLarge />
      </div>
      {/* <!-- Shape Bottom --> */}
      <div className="shape-bottom">
        <img src={shapeBottom} alt="bottom" />
      </div>
    </section>
  )
}

export default App;
