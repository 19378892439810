//import katha from './assets/img/avatar/ABW-Portrait-Katha.jpg'
import joerg from './assets/img/avatar/ABW-Portrait-Joerg-klein.jpg'
import sabine from './assets/img/avatar/ABW-Portrait-Sabine-klein.jpg'
import detlev from './assets/img/avatar/ABW-Portrait-Detlev-klein.jpg'
import katha from './assets/img/avatar/Katha-klein.jpg'
import tbw from './assets/img/avatar/Deutsches-Taubblindenwerk-Logo-klein.png'
import aat from './assets/img/avatar/aat-klein.png'

function App() {
  return (
    <>
      <section id="references" className="review-area bg-gray ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="section-heading text-center">
                <h2>Referenzen</h2>
                <span className="section-span my-3 mt-sm-4 mb-sm-5">Für und mit unseren Kunden.</span>
              </div>
              <div className="text-center">
                <h2>Das sagen die Nutzer von ABWplus!</h2>
                <p className="my-3 mt-sm-4 mb-sm-5">Als ein klares Plus für das „Ambulant Betreute Wohnen“ haben wir die Plattform nicht nur für, sondern auch mit ABW-Anbietern entwickelt. Daher ist uns Feedback immer wichtig, um die Plattform weiter auf die jeweiligen Bedürfnisse anzupassen.</p>
              </div>
            </div>
          </div>


          {/* <!--/.Carousel Wrapper--> */}
          <div id="multi-item-example" className="carousel slide slider-height" data-ride="carousel">

            <a className="carousel-control-prev d-none d-md-block" href="#multi-item-example" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>

            </a>
            <a className="carousel-control-next d-none d-md-block" href="#multi-item-example" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>

            {/* <!--Slides--> */}
            <div className="carousel-inner" role="listbox">

              {/* <!--First slide--> */}
              <div className="carousel-item active">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 res-margin">
                    <div className="single-review">
                      <div className="reviewer media p-4">
                        <div className="reviewer-thumb">
                          <img className="avatar-lg radius-100" src={katha} alt="" loading="lazy" />
                        </div>
                        <div className="reviewer-meta media-body align-self-center ml-4">
                          <h5 className="reviewer-name color-primary mb-2">Katharina Koschel</h5>
                          <h6 className="text-secondary fw-6">Geschäftsführerin<br />ABW - Die Betreuungs GmbH</h6>
                        </div>
                      </div>
                      <div className="card card-top bg-gray border-0">
                        <div className="review-text">
                          <p>„ABWplus ist eine innovative und ressourcensparende Unterstützung in der täglichen Betreuungsarbeit. Ob in der Bedarfsermittlung, bei Arzt- oder Behördenterminen oder beim Fachaustausch: mit nur wenigen Klicks sind, dank digitaler Akten, jederzeit alle wichtigen Dokumente und aussagekräftige Unterlagen zur Hand. Somit können hilfreiche Informationen sofort abgerufen, Fragen und Probleme direkt und unkompliziert vor Ort gelöst werden. Der Betreuungsalltag wird zeitoptimiert und verhilft lösungsorientiert zu arbeiten. Der Fokus liegt jederzeit dort, wo er sein sollte, bei den Betreuten und nicht bei deren Aktenordnern. Ein positiver Nebeneffekt: es reduziert den täglichen Papierverbrauch und schont somit wertvolle Ressourcen."</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-review">
                      <div className="reviewer media p-4">
                        <div className="reviewer-thumb">
                          <img className="avatar-lg radius-100" src={tbw} alt="" loading="lazy" />
                        </div>
                        <div className="reviewer-meta media-body align-self-center ml-4">
                          <h5 className="reviewer-name color-primary mb-2">Ina Radtke</h5>
                          <h6 className="text-secondary fw-6">Sozialpädagogin<br />ABW – Deutsches Taubblindenwerk gemeinnützige GmbH</h6>
                        </div>
                      </div>
                      <div className="card card-top bg-gray border-0">
                        <div className="review-text">
                          <p>„Das Programm vereinfacht auf sehr angenehme Art administrative Arbeitsschritte, wie das unkomplizierte Führen von Zeit- und Leistungsnachweisen und das Erstellen der Rechnungen. Die Darstellung der Dokumentation ist übersichtlich und erleichtert die Übergabe zu Kolleg*innen im Betreuungsprozess. Zudem ist der Kundenservice super und sehr zuverlässig.“</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-review">
                      <div className="reviewer media p-4">
                        <div className="reviewer-thumb">
                          <img className="avatar-lg radius-100" src={joerg} alt="" loading="lazy" />
                        </div>
                        <div className="reviewer-meta media-body align-self-center ml-4">
                          <h5 className="reviewer-name color-primary mb-2">Jörg Ziert</h5>
                          <h6 className="text-secondary fw-6">Betreuer<br />ABW - Die Betreuungs GmbH</h6>
                        </div>
                      </div>
                      <div className="card card-top bg-gray border-0">
                        <div className="review-text">
                          <p>„Die App ist super. Ich habe nicht nur alle nötigen Informationen parat, sondern auch schon meine Dokumentation fertig, wenn ich beim Klienten wieder wegfahre. Ich merke deutlich, dass ich entspannter bin und einfach mehr Zeit habe, um mich um die Menschen zu kümmern.“</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--second slide--> */}
              <div className="carousel-item">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 res-margin">
                    <div className="single-review">
                      <div className="reviewer media p-4">
                        <div className="reviewer-thumb">
                          <img className="avatar-lg radius-100" src={sabine} alt="" loading="lazy" />
                        </div>
                        <div className="reviewer-meta media-body align-self-center ml-4">
                          <h5 className="reviewer-name color-primary mb-2">Sabine Göllnitz-Möbius</h5>
                          <h6 className="text-secondary fw-6">Prokuristin<br />ABW - Die Betreuungs GmbH</h6>
                        </div>
                      </div>
                      <div className="card card-top bg-gray border-0">
                        <div className="review-text">
                          <p>„Ein Großer Vorteil ist, dass alle Dokumente und Informationen digital zur Verfügung stehen und ich immer und überall darauf zugreifen kann. Ich habe einen Überblick über die Personalplanung und den aktuellen Stand der Arbeit mit den Klienten. Ich kann Mitarbeiter gezielt unterstützen und habe die Auslastung im Auge. Durch automatisierte Abläufe ist, eine schnelle Rechnungslegung und Monats-Abrechnung der Mitarbeiter möglich.“</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-review">
                      <div className="reviewer media p-4">
                        <div className="reviewer-thumb">
                          <img className="avatar-lg radius-100" src={detlev} alt="" loading="lazy" />
                        </div>
                        <div className="reviewer-meta media-body align-self-center ml-4">
                          <h5 className="reviewer-name color-primary mb-2">Detlev Fricke</h5>
                          <h6 className="text-secondary fw-6">Geschäftsführer<br />ABW - Die Betreuungs GmbH</h6>
                        </div>
                      </div>
                      <div className="card card-top bg-gray border-0">
                        <div className="review-text">
                          <p>„Für mich als Geschäftsführer dieses Unternehmens ist es wichtig immer einen aktuellen Überblick über das Unternehmen zu haben,
                            sei es der Personaleinsatz die Dokumentation der Tätigkeiten oder die Budgetauslastung, die letztendlich das finanzielle Überleben dieses Unternehmen sichert.
                            Die von uns eingesetzte Software ermöglicht es, die von mir benötigten Informationen mit wenigen Mausklicks zu bekommen.“</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--third slide--> */}
              <div className="carousel-item">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 res-margin">
                    <div className="single-review">
                      <div className="reviewer media p-4">
                        <div className="reviewer-thumb">
                          <img className="avatar-lg radius-100" src={aat} alt="" loading="lazy" />
                        </div>
                        <div className="reviewer-meta media-body align-self-center ml-4">
                          <h5 className="reviewer-name color-primary mb-2">Petra Faber</h5>
                          <h6 className="text-secondary fw-6">Geschäftsführerin<br />AAT - Faber (Ambulantes Assistenz-Team)</h6>
                        </div>
                      </div>
                      <div className="card card-top bg-gray border-0">
                        <div className="review-text">
                          <p>„Wir sind absolut begeistert von der Dokumentation via Sprachsteuerung, die enorm zeitsparend und reibungslos funktioniert. Das gesamte Team hat jederzeit einen Einblick in die Dokumentation und die Übergaben und kann sich somit sehr gut auf den/die nächste/n Klienten/in vorbereiten.
                            Die Zusammenarbeit mit den Software-Entwicklern ist unkompliziert und Neuerungen und Änderungswünsche werden zeitnah umgesetzt und individuell angepasst.“</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-review">
                      <div className="reviewer media p-4">
                        <div className="reviewer-thumb">
                          <img className="avatar-lg radius-100" src={aat} alt="" loading="lazy" />
                        </div>
                        <div className="reviewer-meta media-body align-self-center ml-4">
                          <h5 className="reviewer-name color-primary mb-2">Susanne Wiedemann</h5>
                          <h6 className="text-secondary fw-6">Qualitätsmanagerin<br />AAT - Faber (Ambulantes Assistenz-Team)</h6>
                        </div>
                      </div>
                      <div className="card card-top bg-gray border-0">
                        <div className="review-text">
                          <p>„ABWplus ist eine hervorragende Betriebssoftware, um einen gesamten Überblick über die Einsatzplanung des Personals, die geleisteten Betreuungsstunden und die wirtschaftliche Auslastung des Unternehmens zu erhalten.
Besonders bemerkenswert ist, dass sämtliche Dokumente und somit Arbeitsprozesse überall digital einsehbar sind. Dies erleichtert auch die Zusammenarbeit mit anderen Institutionen im komplementären Bereich.“</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* <!--/.Slides--> */}

            {/* <!--Indicators--> */}
            <ol className="carousel-indicators">
              <li data-target="#multi-item-example" data-slide-to="0" className="active"></li>
              <li data-target="#multi-item-example" data-slide-to="1"></li>
              <li data-target="#multi-item-example" data-slide-to="2"></li>
            </ol>
            {/* <!--/.Indicators--> */}

          </div>
          {/* <!--/.Carousel Wrapper--> */}
        </div>
      </section>
    </>
  )
}

export default App;
