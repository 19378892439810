import Welcome from './Welcome'
import Info from './Info'
import Benefits from './Benefits'
import Pricing from './Pricing'
import References from './References'
import Contact from './Contact'

function App() {
  return (
    <>
      <Welcome/>
      <Info/>
      <Benefits/>
      <References/>
      <Pricing/>
      <Contact/>
    </>
  )}

export default App;
