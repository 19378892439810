import screenschot1 from './assets/img/screenshots/Mockup-Screenshot.png'

function App() {
  return (
    <section id="info" className="section discover-area bg-gray overflow-hidden ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="section-heading text-center">
              <h2 className="text-capitalize">Info</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-between align-items-center">
          <div className="col-12 col-lg-5 order-2 order-lg-1">
            {/* <!-- Discover Thumb --> */}
            <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
              <img src={screenschot1} alt="" loading="lazy" />
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-2">
            {/* <!-- Discover Text --> */}
            <div className="discover-text pt-4 pt-lg-0">
              <div className="d-none d-lg-block">
                <span className="section-span">Alles in Einem.</span>
                <h2 className="pb-4 pb-sm-0">Mehr Zeit für Ihre Berufung.</h2>
                <p className="pt-3 pb-4">Mit ABWplus sparen Sie Zeit und Geld und verschaffen sich Raum für die wirklich wichtigen Dinge.
                  Die Web-basierende Plattform digitalisiert und vereinfacht alle Abläufe, von der Dokumentation bis zur Rechnungsstellung.</p>
              </div>
              <div className="d-block d-lg-none text-center">
                <span className="section-span">Alles in Einem.</span>
                <h2 className="pb-4 pb-sm-0">Mehr Zeit für Ihre Berufung.</h2>
                <p className="pt-3 pb-4">Mit ABWplus sparen Sie Zeit und Geld und verschaffen sich Raum für die wirklich wichtigen Dinge.
                  Die Web-basierende Plattform digitalisiert und vereinfacht alle Abläufe, von der Dokumentation bis zur Rechnungsstellung.</p>
              </div>
              {/* <!-- Check List --> */}
              <p>Weitere Vorteile:</p>
              <ul className="check-list">
                <li className="py-1">
                  {/* <!-- List Box --> */}
                  <div className="list-box media">
                    <span className="media-body pl-4">Eigene App für mobiles Arbeiten</span>
                  </div>
                </li>
                <li className="py-1">
                  {/* <!-- List Box --> */}
                  <div className="list-box media">
                    <span className="media-body pl-4">Digitale Klienten-Akten und einheitliche Rechnungsunterlagen</span>
                  </div>
                </li>
                <li className="py-1">
                  {/* <!-- List Box --> */}
                  <div className="list-box media">
                    <span className="media-body pl-4">QR-Codes für schnelle Zuordnung der Unterlagen</span>
                  </div>
                </li>
                <li className="py-1">
                  {/* <!-- List Box --> */}
                  <div className="list-box media">
                    <span className="media-body pl-4">Digitale Unterschrift für noch mehr Zeitersparnis</span>
                  </div>
                </li>
                <li className="py-1">
                  {/* <!-- List Box --> */}
                  <div className="list-box media">
                    <span className="media-body pl-4">XRechnung für einfachere und schnellere Bezahlungen</span>
                  </div>
                </li>
                <li className="py-1">
                  {/* <!-- List Box --> */}
                  <div className="list-box media">
                    <span className="media-body pl-4">Statistische Auswertung zur Steigerung der Effektivität und Qualität</span>
                  </div>
                </li>
                <li className="py-1">
                  {/* <!-- List Box --> */}
                  <div className="list-box media">
                    <span className="media-body pl-4">DATEV-Schnittstelle für schnelle Rechnungsübermittlung per Knopfdruck</span>
                  </div>
                </li>
                <li className="py-1">
                  {/* <!-- List Box --> */}
                  <div className="list-box media">
                    <span className="media-body pl-4">u.v.m.</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <p className="pt-5 text-center">Gerne unterstützen wir Sie auch bei der Implementierung und Einführung der Software im laufenden Betrieb.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default App;
