import logo from './assets/img/logo/logo.svg'
import logoWhite from './assets/img/logo/logo-white.svg'
import { NavHashLink as NavLink } from 'react-router-hash-link';

function App({ noHeader = false }) {
  let header = "navbar navbar-sticky navbar-expand-lg navbar-dark"
  if (noHeader) {
    header = "navbar navbar-sticky navbar-expand-lg navbar-dark navbar-no-header"
  }
  return (
    <header className={header}>
      <div className="container position-relative">
        <a className="navbar-brand" href="index">
          <img className="navbar-brand-regular" src={logoWhite} alt="brand-logo" />
          <img className="navbar-brand-sticky" src={logo} alt="sticky brand-logo" />
        </a>
        <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="navbar-inner">
          {/* <!--  Mobile Menu Toggler --> */}
          <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <nav>
            <ul className="navbar-nav" id="navbar-nav">
              <li className="nav-item">
                <NavLink className="nav-link" to="/index#info">Info</NavLink>
                {/* <a className="nav-link scroll" href="/index#info">Info</a> */}
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/index#benefits">Ihre Vorteile</NavLink>
                {/* <a className="nav-link scroll" href="#benefits">Ihre Vorteile</a> */}
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/index#references">Referenzen</NavLink>
                {/* <a className="nav-link scroll" href="#review">Referenzen</a> */}
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/index#pricing">Preise</NavLink>
                {/* <a className="nav-link scroll" href="#pricing">Preise</a> */}
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/index#contact">Kontakt</NavLink>
                {/* <a className="nav-link scroll" href="#contact">Kontakt</a> */}
              </li>
              <form className="form-inline">
                <a className="btn btn-purple m-3" href="https://demo.abwplus.de">Demo</a>
              </form>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default App;
