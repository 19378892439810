import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faXing, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function App() {
  let url = "#"
  return (
    <footer className="footer-area bg-overlay">
      {/* <!-- Footer Top --> */}
      {/* <div className="footer-top ptb_100">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="footer-items">
                <a className="navbar-brand" href={url}>
                  <img className="logo" src={logoWhite} alt="" />
                </a>
                <p className="mt-2 mb-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis non, fugit totam vel
                  laboriosam vitae.</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="footer-items">
                <h3 className="footer-title mb-2">Company</h3>
                <ul>
                  <li className="py-2"><a href={url}>Careers</a></li>
                  <li className="py-2"><a href={url}>About Us</a></li>
                  <li className="py-2"><a href={url}>Services</a></li>
                  <li className="py-2"><a href={url}>Blog</a></li>
                  <li className="py-2"><a href={url}>Contact</a></li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="footer-items">
                <h3 className="footer-title mb-2">Resources</h3>
                <ul>
                  <li className="py-2"><a href={url}>Help</a></li>
                  <li className="py-2"><a href={url}>FAQ</a></li>
                  <li className="py-2"><a href={url}>Privacy Policy</a></li>
                  <li className="py-2"><a href={url}>Support</a></li>
                  <li className="py-2"><a href={url}>Terms &amp; Conditions</a></li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="footer-items">
                <h3 className="footer-title mb-2">Support</h3>
                <ul>
                  <li className="py-2"><a href={url}>Products</a></li>
                  <li className="py-2"><a href={url}>FAQ</a></li>
                  <li className="py-2"><a href={url}>Privacy Policy</a></li>
                  <li className="py-2"><a href={url}>About Us</a></li>
                  <li className="py-2"><a href={url}>Download</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Footer Bottom --> */}
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* <!-- Copyright Area --> */}
              <div
                className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                {/* <!-- Copyright Left --> */}
                <div className="copyright-left">&copy; 2022 ABWplus GmbH All rights reserved.</div>
                {/* <!-- Social Icons --> */}
                <div className="social-icons d-flex justify-content-center justify-content-sm-between">
                  <a className="facebook" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/ABWplus">
                    <FontAwesomeIcon icon={faFacebookF} fixedWidth size="2xs" />
                    <FontAwesomeIcon icon={faFacebookF} fixedWidth size="2xs" />
                  </a>
                  <a className="xing" target="_blank" rel="noopener noreferrer" href="https://www.xing.com/pages/abwplus">
                    <FontAwesomeIcon icon={faXing} fixedWidth size="2xs" />
                    <FontAwesomeIcon icon={faXing} fixedWidth size="2xs" />
                  </a>
                  <a className="linkedin" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/abwplus">
                    <FontAwesomeIcon icon={faLinkedin} fixedWidth size="2xs" />
                    <FontAwesomeIcon icon={faLinkedin} fixedWidth size="2xs" />
                  </a>
                </div>
                {/* <!-- Copyright Right --> */}
                <div className="copyright-right">
                  <a href="impressum">Impressum</a> | <a href="datenschutz">Datenschutz</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default App;
