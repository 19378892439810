import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faHome, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <section id="contact" className="section discover-area bg-gray overflow-hidden ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="section-heading text-center">
              <h2>Kontakt</h2>
              <span className="section-span my-3 mt-sm-4 mb-sm-5">Bis gleich.</span>
            </div>
          </div>
        </div>
        <div className="row justify-content-between align-items-center">
          <div className="col-12 col-lg-6 pr-lg-5 order-1 order-lg-1">
            {/* <!-- Contact Box --> */}
            <div className="contact-box bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg">
              {/* <!-- Contact Form --> */}
              <form id="contact-form" method="POST" action="assets/php/top-mail.php">
                <div className="contact-top">
                  <h3 className="contact-title pb-3">Kontaktieren Sie uns:</h3>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <input type="text" className="form-control" name="name" placeholder="Name" required="required" />
                    </div>
                    <div className="form-group">
                      <input type="email" className="form-control" name="email" placeholder="E-Mail" required="required" />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <textarea className="form-control" name="message" placeholder="Nachricht"
                        required="required"></textarea>
                    </div>
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn btn-lg btn-block mt-3"><span className="text-white pr-3">
                      <FontAwesomeIcon icon={faPaperPlane} /></span>Anfrage Senden</button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-2 order-lg-2">
            {/* <!-- Discover Text --> */}
            <div className="discover-text pt-4 pt-lg-0">
              <div className="d-none d-lg-block">
                <h2 className="pb-4 pb-sm-0">Wir sind für Sie da.</h2>
                <p className="pb-4">Haben Sie sich für „Mehr Zeit für Menschlichkeit“ entschieden oder haben Sie Fragen, dann kontaktieren Sie uns gerne.</p>
              </div>
              <div className="d-block d-lg-none text-center">
                <h2 className="pb-4 pb-sm-0">Wir sind für Sie da.</h2>
                <p className="pb-4">Haben Sie sich für „Mehr Zeit für Menschlichkeit“ entschieden oder haben Sie Fragen, dann kontaktieren Sie uns gerne.</p>
              </div>

              <ul>
                <li className="py-3">
                  <div className="media">
                    <div className="social-icon mr-3">
                      <FontAwesomeIcon icon={faHome} />
                    </div>
                    <span className="media-body align-self-center">
                      <h3 className="mb-2">Adresse</h3>
                      <span>Zechenstraße 1, 37431 Bad Lauterberg im Harz</span>
                    </span>
                  </div>
                </li>
                <li className="py-3">
                  <div className="media">
                    <div className="social-icon mr-3">
                      <FontAwesomeIcon icon={faPhoneAlt} />
                    </div>
                    <span className="media-body align-self-center">
                      <h3 className="mb-2">Telefon</h3>
                      <span>+49 55 24 - 85 38 262</span>
                    </span>
                  </div>
                </li>
                <li className="py-3">
                  <a className="media" href="mailto:info@abwplus.de">
                    <div className="social-icon mr-3">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    <span className="media-body align-self-center">
                      <h3 className="mb-2">E-Mail</h3>
                      <span>info@abwplus.de</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default App;
