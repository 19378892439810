import Navigation from './Navigation'
import Footer from './Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <>
      <div id="loader">
        <div className="spinner">
          <div className="dot1"></div>
          <div className="dot2"></div>
        </div>
      </div>
      <div id="scrollUp" title="Scroll To Top">
        <FontAwesomeIcon icon={faArrowUp} fixedWidth size='2xs' />
      </div>
      <div className="main">
        <Navigation />
        <Footer />
      </div>
    </>
  );
}

export default App;
