import thumb1 from './assets/img/services/thumb1.jpg'
import thumb2 from './assets/img/services/thumb2.jpg'
import thumb3 from './assets/img/services/thumb3.jpg'

function App() {
  return (
    <>
      <section id="benefits" className="section discover-area overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="section-heading text-center">
                <h2>Ihre Vorteile</h2>
                <span className="section-span my-3 mt-sm-4 mb-sm-5">Einfach. Hilfreich.</span>
              </div>
              <div className="text-center">
                <h2>Sie profitieren mehrfach,<br />Ihre Klienten ein Leben lang.</h2>
                <p className="my-3 mt-sm-4 mb-sm-5">ABWplus entstand dort, wo es eingesetzt wird. So wurde die Plattform für die Bedürfnisse der einzelnen Nutzer entwickelt und immer wieder optimiert. Heute unterstützt und vereinfacht sie die Arbeit auf allen Ebenen: </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-lg-6 order-1 order-lg-1">
              {/* <!-- Service Text --> */}
              <div className="service-text pt-4 pt-lg-0">
                <span className="section-span">Betreuer*innen:</span>
                <h2 className="mb-4">Näher am Menschen.</h2>
                {/* <!-- Check List --> */}
                <p className="pt-3 pb-4">Mit der eigenen App für das Smartphone haben die Betreuer*innen immer und überall Zugriff auf die nötigen Daten und können ihre Dokumentationen einfach vor Ort erledigen.</p>
                <ul className="check-list">
                  <li className="py-1">
                    {/* <!-- List Box --> */}
                    <div className="list-box media">
                      <span className="media-body pl-4">Überall Zugriff auf die relevanten Dokumente und Informationen</span>
                    </div>
                  </li>
                  <li className="py-1">
                    {/* <!-- List Box --> */}
                    <div className="list-box media">
                      <span className="media-body pl-4">Schnelle und einfache Dokumentation, auch per Spracheingabe</span>
                    </div>
                  </li>
                  <li className="py-1">
                    {/* <!-- List Box --> */}
                    <div className="list-box media">
                      <span className="media-body pl-4">Optimierung der Einsätze und des Arbeitsplans</span>
                    </div>
                  </li>
                  <li className="py-1">
                    {/* <!-- List Box --> */}
                    <div className="list-box media">
                      <span className="media-body pl-4">Spürbar mehr Zeit für die Klienten</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-4 order-2 order-lg-2">
              {/* <!-- Service Thumb --> */}
              <div className="service-thumb mx-auto pt-5 pt-lg-0">
                <img src={thumb1} alt="" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section discover-area overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-lg-4 order-2 order-lg-1">
              {/* <!-- Discover Thumb --> */}
              <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                <img src={thumb2} alt="" loading="lazy" />
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              {/* <!-- Discover Text --> */}
              <div className="discover-text pt-4 pt-lg-0">
                <span className="section-span">Verwaltung:</span>
                <h2 className="pb-4 pb-sm-0">Per Klick zum Ergebnis.</h2>
                <p className="pt-3 pb-4">Ob Rechnungen oder Personalplanung, die ABWplus-Plattform ist die Schaltzentrale, die endlich Übersicht und Ordnung liefert.</p>
                {/* <!-- Check List --> */}
                <ul className="check-list">
                  <li className="py-1">
                    {/* <!-- List Box --> */}
                    <div className="list-box media">
                      <span className="media-body pl-4">Digitalisierung und Standardisierung aller Dokumente</span>
                    </div>
                  </li>
                  <li className="py-1">
                    {/* <!-- List Box --> */}
                    <div className="list-box media">
                      <span className="media-body pl-4">Überblick über die Budget- und Personalauslastung in Echtzeit</span>
                    </div>
                  </li>
                  <li className="py-1">
                    {/* <!-- List Box --> */}
                    <div className="list-box media">
                      <span className="media-body pl-4">Automatische Rechnungszuweisung und Auswertung der Leistungsnachweise</span>
                    </div>
                  </li>
                  <li className="py-1">
                    {/* <!-- List Box --> */}
                    <div className="list-box media">
                      <span className="media-body pl-4">Vereinfachung und Optimierung von Verwaltungsabläufen und Planungen</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section discover-area overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-lg-6 order-1 order-lg-1">
              {/* <!-- Service Text --> */}
              <div className="service-text pt-4 pt-lg-0">
                <span className="section-span">Geschäftsleitung:</span>
                <h2 className="mb-4">Digital in die Zukunft.</h2>
                <p className="pt-3 pb-4">Gerade in der heutigen Zeit ist ABWplus die Plattform für einen zukunftsorientierten ABW-Anbieter. Einfach und hilfreich.</p>
                {/* <!-- Check List --> */}
                <ul className="check-list">
                  <li className="py-1">
                    {/* <!-- List Box --> */}
                    <div className="list-box media">
                      <span className="media-body pl-4">Volle Kostenkontrolle</span>
                    </div>
                  </li>
                  <li className="py-1">
                    {/* <!-- List Box --> */}
                    <div className="list-box media">
                      <span className="media-body pl-4">Statistische Auswertungen</span>
                    </div>
                  </li>
                  <li className="py-1">
                    {/* <!-- List Box --> */}
                    <div className="list-box media">
                      <span className="media-body pl-4">Mitarbeiterentlastungen</span>
                    </div>
                  </li>
                  <li className="py-1">
                    {/* <!-- List Box --> */}
                    <div className="list-box media">
                      <span className="media-body pl-4">Steigerung der Effektivität und Qualität</span>
                    </div>
                  </li>
                  <li className="py-1">
                    {/* <!-- List Box --> */}
                    <div className="list-box media">
                      <span className="media-body pl-4">Zeit- und Geldersparnisse</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-4 order-2 order-lg-2">
              {/* <!-- Service Thumb --> */}
              <div className="service-thumb mx-auto pt-5 pt-lg-0">
                <img src={thumb3} alt="" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
