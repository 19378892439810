import basic from './assets/img/pricing/basic.png'
import premium from './assets/img/pricing/premium.png'

function App() {
  return (
    <section id="pricing" className="section price-plan-area overflow-hidden ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-7">
            {/* <!-- Section Heading --> */}
            <div className="section-heading text-center">
              <h2>Preise</h2>
              <span className="section-span my-3 mt-sm-4 mb-sm-5">Übersichtlich und fair.</span>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-lg-12">
            <div className="row price-plan-wrapper">
              <div className="col-12 col-md-4">
                {/* <!-- Single Price Plan --> */}
                <div className="single-price-plan text-center p-5">
                  {/* <!-- Plan Thumb --> */}
                  {/* <div className="plan-thumb">
                    <img className="price-lg" src={basic} alt="" />
                  </div> */}
                  {/* <!-- Plan Title --> */}
                  <div className="plan-title my-2 my-sm-3">
                    <h3 className="text-uppercase">Standard</h3>
                  </div>
                  {/* <!-- Plan Price --> */}
                  <div className="plan-price pb-2 pb-sm-3">
                    <span className="h1">8</span>
                    <span className="color-primary fw-6">€*</span>
                    <p>pro Klient/mtl.</p>
                  </div>
                  {/* <!-- Plan Description --> */}
                  <div className="plan-description">
                    <ul className="plan-features">
                      <li className="py-2">Web-Plattform</li>
                      <li className="py-2 d-none d-md-block">-</li>
                      <li className="py-2 d-none d-md-block">-</li>
                      <li className="py-2 d-none d-md-block">-</li>
                      <li className="py-2 d-none d-md-block">-</li>
                    </ul>
                  </div>
                  {/* <!-- Plan Button --> */}
                  {/* <div className="plan-button">
                    <a href="#contact" className="btn appmax-btn mt-3">Get Started</a>
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-md-4 mt-4 mt-md-0">
                {/* <!-- Single Price Plan --> */}
                <div className="single-price-plan text-center p-5">
                  {/* <!-- Plan Thumb --> */}
                  {/* <div className="plan-thumb">
                    <img className="price-lg" src={premium} alt="" />
                  </div> */}
                  {/* <!-- Plan Title --> */}
                  <div className="plan-title my-2 my-sm-3">
                    <h3 className="text-uppercase">Premium</h3>
                  </div>
                  {/* <!-- Plan Price --> */}
                  <div className="plan-price pb-2 pb-sm-3">
                    <span className="h1">10</span>
                    <span className="color-primary fw-6">€*</span>
                    <p>pro Klient/mtl.</p>
                  </div>
                  {/* <!-- Plan Description --> */}
                  <div className="plan-description">
                    <ul className="plan-features">
                      <li className="py-2">Web-Plattform</li>
                      <li className="py-2">Handy App (iOS und Android)</li>
                      <li className="py-2 d-none d-md-block">-</li>
                      <li className="py-2 d-none d-md-block">-</li>
                      <li className="py-2 d-none d-md-block">-</li>
                    </ul>
                  </div>
                  {/* <!-- Plan Button --> */}
                  {/* <div className="plan-button">
                    <a href="#contact" className="btn appmax-btn mt-3">Get Started</a>
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-md-4 mt-4 mt-md-0">
                {/* <!-- Single Price Plan --> */}
                <div className="single-price-plan text-center p-5">
                  {/* <!-- Plan Thumb --> */}
                  {/* <div className="plan-thumb">
                    <img className="price-lg" src={premium} alt="" />
                  </div> */}
                  {/* <!-- Plan Title --> */}
                  <div className="plan-title my-2 my-sm-3">
                    <h3 className="text-uppercase">Premium Plus</h3>
                  </div>
                  {/* <!-- Plan Price --> */}
                  <div className="plan-price pb-2 pb-sm-3">
                    <span className="h1">12</span>
                    <span className="color-primary fw-6">€*</span>
                    <p>pro Klient/mtl.</p>
                  </div>
                  {/* <!-- Plan Button --> */}
                  {/* <div className="plan-price pb-2 pb-sm-3 pt-1">
                    <div className="plan-button">
                      <a href="#contact" className="btn appmax-btn mt-3">Kontaktieren Sie uns</a>
                    </div>
                  </div> */}
                  {/* <!-- Plan Description --> */}
                  <div className="plan-description">
                    <ul className="plan-features">
                      <li className="py-2">Web-Plattform</li>
                      <li className="py-2">Handy App (iOS und Android)</li>
                      <li className="py-2">QR-Code-Scanner</li>
                      <li className="py-2">Digitale Unterschrift</li>
                      <li className="py-2">XRechnung</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center pt-5 text-center">
          <div className='col-12'>
            <p>Gerne setzen wir auch individuelle Wünsche um. Sprechen Sie uns einfach an.</p>
          </div>
          <div className="col-12 plan-price pb-2 pb-sm-3 pt-1">
            <div className="plan-button">
              <a href="#contact" className="btn appmax-btn mt-3">Kontaktieren Sie uns</a>
            </div>
          </div>
          <div className='col-12'><p>* zzgl. MwSt.</p></div>
        </div>
      </div>
    </section >
  )
}

export default App;
