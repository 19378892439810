import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import NavigationHeader from './NavigationHeader'
import Home from './Home'
import Datenschutz from './Datenschutz'
import Impressum from './Impressum'

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/datenschutz">
            <NavigationHeader noHeader={false} />
            <Datenschutz />
          </Route>
          <Route path="/impressum">
            <NavigationHeader/>
            <Impressum />
          </Route>
          <Route>
            <NavigationHeader />
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
